import { default as indexkgjeNKgHP9Meta } from "/opt/docker/pages/buyers/[id]/index.vue?macro=true";
import { default as indexUaA4cDWCsgMeta } from "/opt/docker/pages/buyers/[id]/requests/[request]/index.vue?macro=true";
import { default as indexdplcwTiJyOMeta } from "/opt/docker/pages/buyers/[id]/requests/add/index.vue?macro=true";
import { default as indexCm8WOBvXO5Meta } from "/opt/docker/pages/buyers/[id]/requests/index.vue?macro=true";
import { default as indexaSFozrxHCSMeta } from "/opt/docker/pages/buyers/[id]/subscriptions/index.vue?macro=true";
import { default as indexvNCliurYzJMeta } from "/opt/docker/pages/buyers/[id]/warehouse/[warehouse]/index.vue?macro=true";
import { default as indexChziYLYtUxMeta } from "/opt/docker/pages/buyers/[id]/warehouse/add/index.vue?macro=true";
import { default as indexN7nQ7t7MgRMeta } from "/opt/docker/pages/buyers/[id]/warehouse/index.vue?macro=true";
import { default as index0c82FJEYtKMeta } from "/opt/docker/pages/buyers/add/index.vue?macro=true";
import { default as index4P1H8w3obfMeta } from "/opt/docker/pages/buyers/index.vue?macro=true";
import { default as buyers1sP71wwqF7Meta } from "/opt/docker/pages/buyers.vue?macro=true";
import { default as indexVtFG3kshvmMeta } from "/opt/docker/pages/cultures/[id]/index.vue?macro=true";
import { default as indexPvvCh1KJT1Meta } from "/opt/docker/pages/cultures/add/index.vue?macro=true";
import { default as index2i3n2EE26NMeta } from "/opt/docker/pages/cultures/index.vue?macro=true";
import { default as indexArWZvDzPwMMeta } from "/opt/docker/pages/cultures/params/[id]/index.vue?macro=true";
import { default as indexT6nI6T7QihMeta } from "/opt/docker/pages/cultures/params/add/index.vue?macro=true";
import { default as indexP4Vi081b2WMeta } from "/opt/docker/pages/cultures/params/index.vue?macro=true";
import { default as culturesHszBIb1u0uMeta } from "/opt/docker/pages/cultures.vue?macro=true";
import { default as indexsWiwm9unDIMeta } from "/opt/docker/pages/index.vue?macro=true";
import { default as indexPMt2XfF9amMeta } from "/opt/docker/pages/login/change-password/index.vue?macro=true";
import { default as indexRkyr1Sx5qOMeta } from "/opt/docker/pages/login/forgot-password/index.vue?macro=true";
import { default as indexecHqu2clOSMeta } from "/opt/docker/pages/login/index.vue?macro=true";
import { default as indexdUqzgz8GE4Meta } from "/opt/docker/pages/login/registration/index.vue?macro=true";
import { default as indexzEbO3x6IuQMeta } from "/opt/docker/pages/login/registration/link/index.vue?macro=true";
import { default as indexJOffZFp9rKMeta } from "/opt/docker/pages/prices/[id]/index.vue?macro=true";
import { default as indexbVkRwdGxPtMeta } from "/opt/docker/pages/prices/add/index.vue?macro=true";
import { default as index6aQQ0wA5QaMeta } from "/opt/docker/pages/prices/index.vue?macro=true";
import { default as pricesFYZ1HrORvJMeta } from "/opt/docker/pages/prices.vue?macro=true";
import { default as indexWxXR3Mds2pMeta } from "/opt/docker/pages/questionnaire/index.vue?macro=true";
import { default as indexTX9gy6V85IMeta } from "/opt/docker/pages/questionnaire/result/index.vue?macro=true";
import { default as indexaRJye1mRUBMeta } from "/opt/docker/pages/regions/[id]/index.vue?macro=true";
import { default as indexrbsuJt5pOzMeta } from "/opt/docker/pages/regions/add/index.vue?macro=true";
import { default as index8aUAWuwd0SMeta } from "/opt/docker/pages/regions/index.vue?macro=true";
import { default as regions0pHo35WtRRMeta } from "/opt/docker/pages/regions.vue?macro=true";
import { default as indexWKFuxkumLqMeta } from "/opt/docker/pages/reports/index.vue?macro=true";
import { default as reportsw32kJd1wmGMeta } from "/opt/docker/pages/reports.vue?macro=true";
import { default as index6HeGbxJ7MvMeta } from "/opt/docker/pages/suppliers/[id]/call-history/index.vue?macro=true";
import { default as index5JC5DcIyjOMeta } from "/opt/docker/pages/suppliers/[id]/index.vue?macro=true";
import { default as index7bfLuiP0AHMeta } from "/opt/docker/pages/suppliers/[id]/offers/[offer]/index.vue?macro=true";
import { default as indexjC4WXDp2RZMeta } from "/opt/docker/pages/suppliers/[id]/offers/add/index.vue?macro=true";
import { default as index0oUORBVY7HMeta } from "/opt/docker/pages/suppliers/[id]/offers/index.vue?macro=true";
import { default as indexpGdyqWY6rkMeta } from "/opt/docker/pages/suppliers/[id]/subscriptions/index.vue?macro=true";
import { default as indexrSFgVncnHAMeta } from "/opt/docker/pages/suppliers/[id]/warehouse/[warehouse]/index.vue?macro=true";
import { default as indexqagC5KLjPgMeta } from "/opt/docker/pages/suppliers/[id]/warehouse/add/index.vue?macro=true";
import { default as indexkrweqTc0fwMeta } from "/opt/docker/pages/suppliers/[id]/warehouse/index.vue?macro=true";
import { default as index8DheImwiLMMeta } from "/opt/docker/pages/suppliers/add/index.vue?macro=true";
import { default as indexksL0PEmEj6Meta } from "/opt/docker/pages/suppliers/index.vue?macro=true";
import { default as suppliers0VYWiIYsSIMeta } from "/opt/docker/pages/suppliers.vue?macro=true";
import { default as indexXJtlJczl4GMeta } from "/opt/docker/pages/support/index.vue?macro=true";
import { default as indexhuizPpri4tMeta } from "/opt/docker/pages/tariffs/[id]/index.vue?macro=true";
import { default as indexNoaKJAxpidMeta } from "/opt/docker/pages/tariffs/add/index.vue?macro=true";
import { default as indexGp3r5MEAOhMeta } from "/opt/docker/pages/tariffs/index.vue?macro=true";
import { default as tariffs4GcXJW4Ee5Meta } from "/opt/docker/pages/tariffs.vue?macro=true";
import { default as indexyACIkcQdvaMeta } from "/opt/docker/pages/users/[id]/index.vue?macro=true";
import { default as indexiVbEFhTjrDMeta } from "/opt/docker/pages/users/[id]/questionnaire/index.vue?macro=true";
import { default as indexjp41eIG0ddMeta } from "/opt/docker/pages/users/[id]/questionnaire/master/index.vue?macro=true";
import { default as indexW8Pqht3WGrMeta } from "/opt/docker/pages/users/add/index.vue?macro=true";
import { default as indexF0PcAS5JNBMeta } from "/opt/docker/pages/users/index.vue?macro=true";
import { default as userst1cYooPzkpMeta } from "/opt/docker/pages/users.vue?macro=true";
export default [
  {
    name: buyers1sP71wwqF7Meta?.name ?? undefined,
    path: buyers1sP71wwqF7Meta?.path ?? "/buyers",
    meta: buyers1sP71wwqF7Meta || {},
    alias: buyers1sP71wwqF7Meta?.alias || [],
    redirect: buyers1sP71wwqF7Meta?.redirect,
    component: () => import("/opt/docker/pages/buyers.vue").then(m => m.default || m),
    children: [
  {
    name: indexkgjeNKgHP9Meta?.name ?? "buyers-id",
    path: indexkgjeNKgHP9Meta?.path ?? ":id()",
    meta: indexkgjeNKgHP9Meta || {},
    alias: indexkgjeNKgHP9Meta?.alias || [],
    redirect: indexkgjeNKgHP9Meta?.redirect,
    component: () => import("/opt/docker/pages/buyers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUaA4cDWCsgMeta?.name ?? "buyers-id-requests-request",
    path: indexUaA4cDWCsgMeta?.path ?? ":id()/requests/:request()",
    meta: indexUaA4cDWCsgMeta || {},
    alias: indexUaA4cDWCsgMeta?.alias || [],
    redirect: indexUaA4cDWCsgMeta?.redirect,
    component: () => import("/opt/docker/pages/buyers/[id]/requests/[request]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdplcwTiJyOMeta?.name ?? "buyers-id-requests-add",
    path: indexdplcwTiJyOMeta?.path ?? ":id()/requests/add",
    meta: indexdplcwTiJyOMeta || {},
    alias: indexdplcwTiJyOMeta?.alias || [],
    redirect: indexdplcwTiJyOMeta?.redirect,
    component: () => import("/opt/docker/pages/buyers/[id]/requests/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexCm8WOBvXO5Meta?.name ?? "buyers-id-requests",
    path: indexCm8WOBvXO5Meta?.path ?? ":id()/requests",
    meta: indexCm8WOBvXO5Meta || {},
    alias: indexCm8WOBvXO5Meta?.alias || [],
    redirect: indexCm8WOBvXO5Meta?.redirect,
    component: () => import("/opt/docker/pages/buyers/[id]/requests/index.vue").then(m => m.default || m)
  },
  {
    name: indexaSFozrxHCSMeta?.name ?? "buyers-id-subscriptions",
    path: indexaSFozrxHCSMeta?.path ?? ":id()/subscriptions",
    meta: indexaSFozrxHCSMeta || {},
    alias: indexaSFozrxHCSMeta?.alias || [],
    redirect: indexaSFozrxHCSMeta?.redirect,
    component: () => import("/opt/docker/pages/buyers/[id]/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: indexvNCliurYzJMeta?.name ?? "buyers-id-warehouse-warehouse",
    path: indexvNCliurYzJMeta?.path ?? ":id()/warehouse/:warehouse()",
    meta: indexvNCliurYzJMeta || {},
    alias: indexvNCliurYzJMeta?.alias || [],
    redirect: indexvNCliurYzJMeta?.redirect,
    component: () => import("/opt/docker/pages/buyers/[id]/warehouse/[warehouse]/index.vue").then(m => m.default || m)
  },
  {
    name: indexChziYLYtUxMeta?.name ?? "buyers-id-warehouse-add",
    path: indexChziYLYtUxMeta?.path ?? ":id()/warehouse/add",
    meta: indexChziYLYtUxMeta || {},
    alias: indexChziYLYtUxMeta?.alias || [],
    redirect: indexChziYLYtUxMeta?.redirect,
    component: () => import("/opt/docker/pages/buyers/[id]/warehouse/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7nQ7t7MgRMeta?.name ?? "buyers-id-warehouse",
    path: indexN7nQ7t7MgRMeta?.path ?? ":id()/warehouse",
    meta: indexN7nQ7t7MgRMeta || {},
    alias: indexN7nQ7t7MgRMeta?.alias || [],
    redirect: indexN7nQ7t7MgRMeta?.redirect,
    component: () => import("/opt/docker/pages/buyers/[id]/warehouse/index.vue").then(m => m.default || m)
  },
  {
    name: index0c82FJEYtKMeta?.name ?? "buyers-add",
    path: index0c82FJEYtKMeta?.path ?? "add",
    meta: index0c82FJEYtKMeta || {},
    alias: index0c82FJEYtKMeta?.alias || [],
    redirect: index0c82FJEYtKMeta?.redirect,
    component: () => import("/opt/docker/pages/buyers/add/index.vue").then(m => m.default || m)
  },
  {
    name: index4P1H8w3obfMeta?.name ?? "buyers",
    path: index4P1H8w3obfMeta?.path ?? "",
    meta: index4P1H8w3obfMeta || {},
    alias: index4P1H8w3obfMeta?.alias || [],
    redirect: index4P1H8w3obfMeta?.redirect,
    component: () => import("/opt/docker/pages/buyers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: culturesHszBIb1u0uMeta?.name ?? undefined,
    path: culturesHszBIb1u0uMeta?.path ?? "/cultures",
    meta: culturesHszBIb1u0uMeta || {},
    alias: culturesHszBIb1u0uMeta?.alias || [],
    redirect: culturesHszBIb1u0uMeta?.redirect,
    component: () => import("/opt/docker/pages/cultures.vue").then(m => m.default || m),
    children: [
  {
    name: indexVtFG3kshvmMeta?.name ?? "cultures-id",
    path: indexVtFG3kshvmMeta?.path ?? ":id()",
    meta: indexVtFG3kshvmMeta || {},
    alias: indexVtFG3kshvmMeta?.alias || [],
    redirect: indexVtFG3kshvmMeta?.redirect,
    component: () => import("/opt/docker/pages/cultures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPvvCh1KJT1Meta?.name ?? "cultures-add",
    path: indexPvvCh1KJT1Meta?.path ?? "add",
    meta: indexPvvCh1KJT1Meta || {},
    alias: indexPvvCh1KJT1Meta?.alias || [],
    redirect: indexPvvCh1KJT1Meta?.redirect,
    component: () => import("/opt/docker/pages/cultures/add/index.vue").then(m => m.default || m)
  },
  {
    name: index2i3n2EE26NMeta?.name ?? "cultures",
    path: index2i3n2EE26NMeta?.path ?? "",
    meta: index2i3n2EE26NMeta || {},
    alias: index2i3n2EE26NMeta?.alias || [],
    redirect: index2i3n2EE26NMeta?.redirect,
    component: () => import("/opt/docker/pages/cultures/index.vue").then(m => m.default || m)
  },
  {
    name: indexArWZvDzPwMMeta?.name ?? "cultures-params-id",
    path: indexArWZvDzPwMMeta?.path ?? "params/:id()",
    meta: indexArWZvDzPwMMeta || {},
    alias: indexArWZvDzPwMMeta?.alias || [],
    redirect: indexArWZvDzPwMMeta?.redirect,
    component: () => import("/opt/docker/pages/cultures/params/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexT6nI6T7QihMeta?.name ?? "cultures-params-add",
    path: indexT6nI6T7QihMeta?.path ?? "params/add",
    meta: indexT6nI6T7QihMeta || {},
    alias: indexT6nI6T7QihMeta?.alias || [],
    redirect: indexT6nI6T7QihMeta?.redirect,
    component: () => import("/opt/docker/pages/cultures/params/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexP4Vi081b2WMeta?.name ?? "cultures-params",
    path: indexP4Vi081b2WMeta?.path ?? "params",
    meta: indexP4Vi081b2WMeta || {},
    alias: indexP4Vi081b2WMeta?.alias || [],
    redirect: indexP4Vi081b2WMeta?.redirect,
    component: () => import("/opt/docker/pages/cultures/params/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsWiwm9unDIMeta?.name ?? "index",
    path: indexsWiwm9unDIMeta?.path ?? "/",
    meta: indexsWiwm9unDIMeta || {},
    alias: indexsWiwm9unDIMeta?.alias || [],
    redirect: indexsWiwm9unDIMeta?.redirect,
    component: () => import("/opt/docker/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMt2XfF9amMeta?.name ?? "login-change-password",
    path: indexPMt2XfF9amMeta?.path ?? "/login/change-password",
    meta: indexPMt2XfF9amMeta || {},
    alias: indexPMt2XfF9amMeta?.alias || [],
    redirect: indexPMt2XfF9amMeta?.redirect,
    component: () => import("/opt/docker/pages/login/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexRkyr1Sx5qOMeta?.name ?? "login-forgot-password",
    path: indexRkyr1Sx5qOMeta?.path ?? "/login/forgot-password",
    meta: indexRkyr1Sx5qOMeta || {},
    alias: indexRkyr1Sx5qOMeta?.alias || [],
    redirect: indexRkyr1Sx5qOMeta?.redirect,
    component: () => import("/opt/docker/pages/login/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexecHqu2clOSMeta?.name ?? "login",
    path: indexecHqu2clOSMeta?.path ?? "/login",
    meta: indexecHqu2clOSMeta || {},
    alias: indexecHqu2clOSMeta?.alias || [],
    redirect: indexecHqu2clOSMeta?.redirect,
    component: () => import("/opt/docker/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUqzgz8GE4Meta?.name ?? "login-registration",
    path: indexdUqzgz8GE4Meta?.path ?? "/login/registration",
    meta: indexdUqzgz8GE4Meta || {},
    alias: indexdUqzgz8GE4Meta?.alias || [],
    redirect: indexdUqzgz8GE4Meta?.redirect,
    component: () => import("/opt/docker/pages/login/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexzEbO3x6IuQMeta?.name ?? "login-registration-link",
    path: indexzEbO3x6IuQMeta?.path ?? "/login/registration/link",
    meta: indexzEbO3x6IuQMeta || {},
    alias: indexzEbO3x6IuQMeta?.alias || [],
    redirect: indexzEbO3x6IuQMeta?.redirect,
    component: () => import("/opt/docker/pages/login/registration/link/index.vue").then(m => m.default || m)
  },
  {
    name: pricesFYZ1HrORvJMeta?.name ?? undefined,
    path: pricesFYZ1HrORvJMeta?.path ?? "/prices",
    meta: pricesFYZ1HrORvJMeta || {},
    alias: pricesFYZ1HrORvJMeta?.alias || [],
    redirect: pricesFYZ1HrORvJMeta?.redirect,
    component: () => import("/opt/docker/pages/prices.vue").then(m => m.default || m),
    children: [
  {
    name: indexJOffZFp9rKMeta?.name ?? "prices-id",
    path: indexJOffZFp9rKMeta?.path ?? ":id()",
    meta: indexJOffZFp9rKMeta || {},
    alias: indexJOffZFp9rKMeta?.alias || [],
    redirect: indexJOffZFp9rKMeta?.redirect,
    component: () => import("/opt/docker/pages/prices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbVkRwdGxPtMeta?.name ?? "prices-add",
    path: indexbVkRwdGxPtMeta?.path ?? "add",
    meta: indexbVkRwdGxPtMeta || {},
    alias: indexbVkRwdGxPtMeta?.alias || [],
    redirect: indexbVkRwdGxPtMeta?.redirect,
    component: () => import("/opt/docker/pages/prices/add/index.vue").then(m => m.default || m)
  },
  {
    name: index6aQQ0wA5QaMeta?.name ?? "prices",
    path: index6aQQ0wA5QaMeta?.path ?? "",
    meta: index6aQQ0wA5QaMeta || {},
    alias: index6aQQ0wA5QaMeta?.alias || [],
    redirect: index6aQQ0wA5QaMeta?.redirect,
    component: () => import("/opt/docker/pages/prices/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexWxXR3Mds2pMeta?.name ?? "questionnaire",
    path: indexWxXR3Mds2pMeta?.path ?? "/questionnaire",
    meta: indexWxXR3Mds2pMeta || {},
    alias: indexWxXR3Mds2pMeta?.alias || [],
    redirect: indexWxXR3Mds2pMeta?.redirect,
    component: () => import("/opt/docker/pages/questionnaire/index.vue").then(m => m.default || m)
  },
  {
    name: indexTX9gy6V85IMeta?.name ?? "questionnaire-result",
    path: indexTX9gy6V85IMeta?.path ?? "/questionnaire/result",
    meta: indexTX9gy6V85IMeta || {},
    alias: indexTX9gy6V85IMeta?.alias || [],
    redirect: indexTX9gy6V85IMeta?.redirect,
    component: () => import("/opt/docker/pages/questionnaire/result/index.vue").then(m => m.default || m)
  },
  {
    name: regions0pHo35WtRRMeta?.name ?? undefined,
    path: regions0pHo35WtRRMeta?.path ?? "/regions",
    meta: regions0pHo35WtRRMeta || {},
    alias: regions0pHo35WtRRMeta?.alias || [],
    redirect: regions0pHo35WtRRMeta?.redirect,
    component: () => import("/opt/docker/pages/regions.vue").then(m => m.default || m),
    children: [
  {
    name: indexaRJye1mRUBMeta?.name ?? "regions-id",
    path: indexaRJye1mRUBMeta?.path ?? ":id()",
    meta: indexaRJye1mRUBMeta || {},
    alias: indexaRJye1mRUBMeta?.alias || [],
    redirect: indexaRJye1mRUBMeta?.redirect,
    component: () => import("/opt/docker/pages/regions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrbsuJt5pOzMeta?.name ?? "regions-add",
    path: indexrbsuJt5pOzMeta?.path ?? "add",
    meta: indexrbsuJt5pOzMeta || {},
    alias: indexrbsuJt5pOzMeta?.alias || [],
    redirect: indexrbsuJt5pOzMeta?.redirect,
    component: () => import("/opt/docker/pages/regions/add/index.vue").then(m => m.default || m)
  },
  {
    name: index8aUAWuwd0SMeta?.name ?? "regions",
    path: index8aUAWuwd0SMeta?.path ?? "",
    meta: index8aUAWuwd0SMeta || {},
    alias: index8aUAWuwd0SMeta?.alias || [],
    redirect: index8aUAWuwd0SMeta?.redirect,
    component: () => import("/opt/docker/pages/regions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: reportsw32kJd1wmGMeta?.name ?? undefined,
    path: reportsw32kJd1wmGMeta?.path ?? "/reports",
    meta: reportsw32kJd1wmGMeta || {},
    alias: reportsw32kJd1wmGMeta?.alias || [],
    redirect: reportsw32kJd1wmGMeta?.redirect,
    component: () => import("/opt/docker/pages/reports.vue").then(m => m.default || m),
    children: [
  {
    name: indexWKFuxkumLqMeta?.name ?? "reports",
    path: indexWKFuxkumLqMeta?.path ?? "",
    meta: indexWKFuxkumLqMeta || {},
    alias: indexWKFuxkumLqMeta?.alias || [],
    redirect: indexWKFuxkumLqMeta?.redirect,
    component: () => import("/opt/docker/pages/reports/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: suppliers0VYWiIYsSIMeta?.name ?? undefined,
    path: suppliers0VYWiIYsSIMeta?.path ?? "/suppliers",
    meta: suppliers0VYWiIYsSIMeta || {},
    alias: suppliers0VYWiIYsSIMeta?.alias || [],
    redirect: suppliers0VYWiIYsSIMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers.vue").then(m => m.default || m),
    children: [
  {
    name: index6HeGbxJ7MvMeta?.name ?? "suppliers-id-call-history",
    path: index6HeGbxJ7MvMeta?.path ?? ":id()/call-history",
    meta: index6HeGbxJ7MvMeta || {},
    alias: index6HeGbxJ7MvMeta?.alias || [],
    redirect: index6HeGbxJ7MvMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/call-history/index.vue").then(m => m.default || m)
  },
  {
    name: index5JC5DcIyjOMeta?.name ?? "suppliers-id",
    path: index5JC5DcIyjOMeta?.path ?? ":id()",
    meta: index5JC5DcIyjOMeta || {},
    alias: index5JC5DcIyjOMeta?.alias || [],
    redirect: index5JC5DcIyjOMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index7bfLuiP0AHMeta?.name ?? "suppliers-id-offers-offer",
    path: index7bfLuiP0AHMeta?.path ?? ":id()/offers/:offer()",
    meta: index7bfLuiP0AHMeta || {},
    alias: index7bfLuiP0AHMeta?.alias || [],
    redirect: index7bfLuiP0AHMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/offers/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjC4WXDp2RZMeta?.name ?? "suppliers-id-offers-add",
    path: indexjC4WXDp2RZMeta?.path ?? ":id()/offers/add",
    meta: indexjC4WXDp2RZMeta || {},
    alias: indexjC4WXDp2RZMeta?.alias || [],
    redirect: indexjC4WXDp2RZMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/offers/add/index.vue").then(m => m.default || m)
  },
  {
    name: index0oUORBVY7HMeta?.name ?? "suppliers-id-offers",
    path: index0oUORBVY7HMeta?.path ?? ":id()/offers",
    meta: index0oUORBVY7HMeta || {},
    alias: index0oUORBVY7HMeta?.alias || [],
    redirect: index0oUORBVY7HMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexpGdyqWY6rkMeta?.name ?? "suppliers-id-subscriptions",
    path: indexpGdyqWY6rkMeta?.path ?? ":id()/subscriptions",
    meta: indexpGdyqWY6rkMeta || {},
    alias: indexpGdyqWY6rkMeta?.alias || [],
    redirect: indexpGdyqWY6rkMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: indexrSFgVncnHAMeta?.name ?? "suppliers-id-warehouse-warehouse",
    path: indexrSFgVncnHAMeta?.path ?? ":id()/warehouse/:warehouse()",
    meta: indexrSFgVncnHAMeta || {},
    alias: indexrSFgVncnHAMeta?.alias || [],
    redirect: indexrSFgVncnHAMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/warehouse/[warehouse]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqagC5KLjPgMeta?.name ?? "suppliers-id-warehouse-add",
    path: indexqagC5KLjPgMeta?.path ?? ":id()/warehouse/add",
    meta: indexqagC5KLjPgMeta || {},
    alias: indexqagC5KLjPgMeta?.alias || [],
    redirect: indexqagC5KLjPgMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/warehouse/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexkrweqTc0fwMeta?.name ?? "suppliers-id-warehouse",
    path: indexkrweqTc0fwMeta?.path ?? ":id()/warehouse",
    meta: indexkrweqTc0fwMeta || {},
    alias: indexkrweqTc0fwMeta?.alias || [],
    redirect: indexkrweqTc0fwMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/[id]/warehouse/index.vue").then(m => m.default || m)
  },
  {
    name: index8DheImwiLMMeta?.name ?? "suppliers-add",
    path: index8DheImwiLMMeta?.path ?? "add",
    meta: index8DheImwiLMMeta || {},
    alias: index8DheImwiLMMeta?.alias || [],
    redirect: index8DheImwiLMMeta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexksL0PEmEj6Meta?.name ?? "suppliers",
    path: indexksL0PEmEj6Meta?.path ?? "",
    meta: indexksL0PEmEj6Meta || {},
    alias: indexksL0PEmEj6Meta?.alias || [],
    redirect: indexksL0PEmEj6Meta?.redirect,
    component: () => import("/opt/docker/pages/suppliers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexXJtlJczl4GMeta?.name ?? "support",
    path: indexXJtlJczl4GMeta?.path ?? "/support",
    meta: indexXJtlJczl4GMeta || {},
    alias: indexXJtlJczl4GMeta?.alias || [],
    redirect: indexXJtlJczl4GMeta?.redirect,
    component: () => import("/opt/docker/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: tariffs4GcXJW4Ee5Meta?.name ?? undefined,
    path: tariffs4GcXJW4Ee5Meta?.path ?? "/tariffs",
    meta: tariffs4GcXJW4Ee5Meta || {},
    alias: tariffs4GcXJW4Ee5Meta?.alias || [],
    redirect: tariffs4GcXJW4Ee5Meta?.redirect,
    component: () => import("/opt/docker/pages/tariffs.vue").then(m => m.default || m),
    children: [
  {
    name: indexhuizPpri4tMeta?.name ?? "tariffs-id",
    path: indexhuizPpri4tMeta?.path ?? ":id()",
    meta: indexhuizPpri4tMeta || {},
    alias: indexhuizPpri4tMeta?.alias || [],
    redirect: indexhuizPpri4tMeta?.redirect,
    component: () => import("/opt/docker/pages/tariffs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNoaKJAxpidMeta?.name ?? "tariffs-add",
    path: indexNoaKJAxpidMeta?.path ?? "add",
    meta: indexNoaKJAxpidMeta || {},
    alias: indexNoaKJAxpidMeta?.alias || [],
    redirect: indexNoaKJAxpidMeta?.redirect,
    component: () => import("/opt/docker/pages/tariffs/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexGp3r5MEAOhMeta?.name ?? "tariffs",
    path: indexGp3r5MEAOhMeta?.path ?? "",
    meta: indexGp3r5MEAOhMeta || {},
    alias: indexGp3r5MEAOhMeta?.alias || [],
    redirect: indexGp3r5MEAOhMeta?.redirect,
    component: () => import("/opt/docker/pages/tariffs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userst1cYooPzkpMeta?.name ?? undefined,
    path: userst1cYooPzkpMeta?.path ?? "/users",
    meta: userst1cYooPzkpMeta || {},
    alias: userst1cYooPzkpMeta?.alias || [],
    redirect: userst1cYooPzkpMeta?.redirect,
    component: () => import("/opt/docker/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: indexyACIkcQdvaMeta?.name ?? "users-id",
    path: indexyACIkcQdvaMeta?.path ?? ":id()",
    meta: indexyACIkcQdvaMeta || {},
    alias: indexyACIkcQdvaMeta?.alias || [],
    redirect: indexyACIkcQdvaMeta?.redirect,
    component: () => import("/opt/docker/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiVbEFhTjrDMeta?.name ?? "users-id-questionnaire",
    path: indexiVbEFhTjrDMeta?.path ?? ":id()/questionnaire",
    meta: indexiVbEFhTjrDMeta || {},
    alias: indexiVbEFhTjrDMeta?.alias || [],
    redirect: indexiVbEFhTjrDMeta?.redirect,
    component: () => import("/opt/docker/pages/users/[id]/questionnaire/index.vue").then(m => m.default || m)
  },
  {
    name: indexjp41eIG0ddMeta?.name ?? "users-id-questionnaire-master",
    path: indexjp41eIG0ddMeta?.path ?? ":id()/questionnaire/master",
    meta: indexjp41eIG0ddMeta || {},
    alias: indexjp41eIG0ddMeta?.alias || [],
    redirect: indexjp41eIG0ddMeta?.redirect,
    component: () => import("/opt/docker/pages/users/[id]/questionnaire/master/index.vue").then(m => m.default || m)
  },
  {
    name: indexW8Pqht3WGrMeta?.name ?? "users-add",
    path: indexW8Pqht3WGrMeta?.path ?? "add",
    meta: indexW8Pqht3WGrMeta || {},
    alias: indexW8Pqht3WGrMeta?.alias || [],
    redirect: indexW8Pqht3WGrMeta?.redirect,
    component: () => import("/opt/docker/pages/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexF0PcAS5JNBMeta?.name ?? "users",
    path: indexF0PcAS5JNBMeta?.path ?? "",
    meta: indexF0PcAS5JNBMeta || {},
    alias: indexF0PcAS5JNBMeta?.alias || [],
    redirect: indexF0PcAS5JNBMeta?.redirect,
    component: () => import("/opt/docker/pages/users/index.vue").then(m => m.default || m)
  }
]
  }
]