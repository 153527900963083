import * as Sentry from "@sentry/vue";
import { useUser } from "~/composables/useUser";
import { useRuntimeConfig, useRouter, defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
	const router = useRouter();
	const env = useRuntimeConfig();
	const ignoreRules = [/\[GET] "\/api\/auth\/session\?callbackUrl/, /Page not found/];

	Sentry.init({
		app: nuxtApp.vueApp,
		dsn: env.public.SENTRY_DSN,
		environment: env.public.SENTRY_ENV,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracesSampleRate: 1.0,
		logErrors: true,
		ignoreErrors: ignoreRules,
		ignoreTransactions: ignoreRules,
	});

	nuxtApp.hook("app:error", (error) => {
		const user = useUser();
		const userInfo = {
			user_guid: user?.userId || "ID не определен",
			user_name: user?.userName || "Имя не определено",
			user_role: user?.userRoles || "Роль не определена",
			user_login: user?.userLogin || "Логин не определен",
		};

		Sentry.captureException(error, {
			user: {
				...userInfo,
			},
			tags: {
				...userInfo,
			},
		});
	});

	nuxtApp.provide("Sentry", Sentry);
});
