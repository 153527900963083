import VuePlyr from "vue-plyr";
import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(VuePlyr, {
		plyr: {
			settings: [],
			speed: {
				selected: 1,
				options: [1, 1.25, 1.5, 1.75, 2, 4],
			},
			autoplay: true,
		},
	});
});
