import { defineNuxtPlugin, useRuntimeConfig } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();

	let apiDomain = config.public.API_URL;
	if (typeof process !== "undefined" && process?.server) apiDomain = config.public.REST_API_SERVER_BASE_URL;

	const v1 = apiDomain.endsWith("/") ? `${apiDomain}api` : `${apiDomain}/api`;
	const v0 = apiDomain.endsWith("/") ? `${apiDomain}api/v0` : `${apiDomain}/api/v0`;

	nuxtApp.api = {
		// Пользователь
		login: `${v1}/user/login`,
		refresh: `${v1}/user/token_refresh`,
		logout: `${v1}/user/logout`,
		info: `${v1}/user/me`,
		// Список пользователей
		users: (noGroup = 0) => `${v1}/admin/users?nogroup=${noGroup}`,
		// Список ролей
		roles: `${v1}/admin/users/roles`,
		// Список агентов
		agents: `${v1}/admin/users/agents`,
		// Справочники
		/// / Список культур
		cultures: `${v1}/cultures`,
		/// / Обновление культуры
		culturesById: (id) => `${v1}/cultures/${id}`,
		/// / Список регионов
		regions: `${v1}/regions`,
		/// / Обновление региона
		regionsById: (id) => `${v1}/regions/${id}`,
		/// / Список тарифов
		tariffs: `${v1}/tariffs`,
		/// / Обновление тарифа
		tariffsById: (id) => `${v1}/tariffs/${id}`,
		/// / Список показателей качества
		qualities: `${v1}/qualities`,
		/// / Обновление показателя качества
		qualitiesById: (id) => `${v1}/qualities/${id}`,
		/// / Список региональных центров влияния цены
		rCenters: `${v1}/rcenters`,
		/// / Обновление паритетной цены в РЦВ
		rCentersById: (id) => `${v1}/rcenters/${id}`,
		/// / Список типов людей
		userTypes: `${v1}/usertypes`,
		/// / Список типов складов
		warehousesTypes: `${v1}/warehouses/types`,
		// Другое
		/// / Проверка ИНН
		inn: `${v1}/inn/`,
		// Клиенты
		/// / Список клиентов
		/// / Добавление клиента
		clients: `${v1}/clients`,
		/// / Получить клиента по ID
		/// / Обновление клиента
		clientsById: (id) => `${v1}/clients/${id}`,
		/// / Получить ближайшие РЦВ к складам клиента по ID и культуре
		clientsRcvByIdCulture: (id, cultureId, agentId) => `${v1}/clients/${id}/warehouses?rcv=1&culture=${cultureId}&agent=${agentId}`,
		/// / Список складов
		/// / Добавить склад
		warehouses: `${v1}/warehouses`,
		/// / Список сниппетов для клиента
		clientsSnippets: (id) => `${v1}/clients/${id}/snippets`,
		// Фермеры
		/// / Список фермеров
		/// / Добавление фермера
		farmers: `${v1}/farmers`,
		/// / Фермер по ID
		/// / Обновление фермера
		farmersById: (id) => `${v1}/farmers/${id}`,
		/// / Список сниппетов для фермера
		farmersSnippets: (id) => `${v1}/farmers/${id}/snippets`,
		// Склады
		/// / Склад по ID
		/// / Обновить склад
		warehousesById: (id) => `${v1}/warehouses/${id}`,
		// Предложения
		/// / Список предложений
		/// / Добавление предложения
		offers: `${v1}/offers`,
		/// / Предложение по ID
		/// / Обновление предложения
		offersById: (id) => `${v1}/offers/${id}`,
		/// / Спрос на предложение
		offersDemand: (id) => `${v1}/offers/${id}/demand`,
		// Запросы
		/// / Список запросов
		/// / Добавление запроса
		requests: `${v1}/requests`,
		/// / Запрос по ID
		/// / Обновление запроса
		requestsById: (id) => `${v1}/requests/${id}`,
		// Подбор
		/// / Список соответствий
		matches: `${v1}/matches`,
		/// / Список сниппетов соответствия
		matchesSnippets: (id) => `${v1}/matches/${id}/snippets`,
		/// / Расчет ЦСМ
		matchesCsm: (id) => `${v1}/matches/${id}/csm`,
		/// / Список ВП
		/// / Добавить ВП
		counteroffers: `${v1}/counteroffers`,
		/// / Встречное предложение по ID
		counteroffersById: (id) => `${v1}/counteroffers/${id}`,
		// Команды
		/// / Регистрация внешних событий через вебхуки
		webhooks: (id) => `${v1}/webhooks/${id}`,
		// Привязка сущностей к агенту (Админ / Орг)
		bindEntityToAgent: (id) => `${v1}/agent/${id}/link`,
		// Привязка фермера к агенту (Агент)
		bindFarmerToAgent: (id) => `${v1}/farmers/${id}/linkedme`,
		bindClientToAgent: (id) => `${v1}/clients/${id}/linkedme`,
		// Перепривязка запросов РЦВ
		rcentersRequestUpdate: (oldRcvId) => `${v1}/rcenters/${oldRcvId}/request-update`,
		// Добавление пользователя
		addUser: `${v1}/admin/users/add`,
		// Обновление пользователя
		updateUser: (userId) => `${v1}/admin/users/${userId}`,
		// Отправить приглашение
		sendInvite: (userId) => `${v1}/user/${userId}/invite`,
		// Получение данных пользователя по ID
		getUserById: (userId) => `${v1}/user/${userId}`,
		// Активация | Деактивация пользователей
		// event может быть enable или disable
		banUsers: (event) => `${v1}/admin/users/${event}`,
		// Проверка секрета и получение информации пользователя
		userCheckSecret: (userId, secret) => `${v1}/user/${userId}/check-secret?secret=${secret}`,
		// Обновление пользователя после перехода по ссылке | PUT
		updateUserLink: (userId) => `${v1}/user/${userId}/update`,
		// Сброс пароля пользователя после перехода по ссылке | POST
		resetPasswordUserLink: (userId) => `${v1}/user/${userId}/forgot-password`,
		// Отправить код для подтверждения телефона по СМС
		userSmsCheck: (userId) => `${v1}/user/${userId}/sms-check`,
		// Получить историю звонков фермера
		callHistory: (userId) => `${v1}/farmers/${userId}/phonecalls`,
		// Запись звонка по ID
		getRecordingCall: (callId) => `${v1}/phonecalls/${callId}`,
		// Получить список районов
		getArea: `${v1}/areas`,
		// Получить список районов по региону
		getAreaByRegion: (regionId) => `${v1}/areas?filter[region]=${regionId}`,
		support: `${v1}/support`,
		updateAiCount: (userId) => `${v0}/user/${userId}/ai-notify`,
		questionnaireUserTypes: `${v1}/user/questionnaire-user-types`,
		userQuestionnaire: (userId) => `${v1}/user/${userId}/questionnaire`,
		sendUserQuestionnaire: (userId) => `${v1}/user/${userId}/questionnaire/send`,
		userQuestionnaireMaster: (userId) => `${v1}/user/${userId}/questionnaire/master`,
		checkQuestionnaireSecret: (userId, code) => `${v1}/user/${userId}/check-questionnaire-secret?secret=${code}`,
		cloneUserQuestionnaireMaster: (userId) => `${v1}/user/${userId}/questionnaire/master/clone`,
	};
});
