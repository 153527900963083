export default defineNuxtPlugin((nuxtApp) => {
	class SaveRouteToLocal {
		// Имя для сохранения маршрута в локальном хранилище
		localStorageName: string;
		// Переменная для хранения предыдущего маршрута пользователя
		previousRoute: string;

		// Конструктор класса
		constructor(localStorageName: string) {
			// Инициализация имени маршрута в локальном хранилище
			this.localStorageName = localStorageName;
			// Получение предыдущего маршрута пользователя
			this.previousRoute = this.getPreviousRoute();
			// Сохранение предыдущего маршрута
			this.savePreviousRoute();
		}

		// Метод для получения предыдущего маршрута
		getPreviousRoute() {
			const router = useRouter();
			const route = useRoute();
			return <string>router.options.history.state.back || <string>localStorage.getItem(this.localStorageName) || route.fullPath;
		}

		// Метод для сохранения предыдущего маршрута
		savePreviousRoute() {
			localStorage.setItem(this.localStorageName, <string>this.getPreviousRoute());
		}
	}

	// Добавляем в NuxtApp наш класс, чтобы мы могли им пользоваться в других местах приложения
	nuxtApp.provide("SaveRouteToLocal", SaveRouteToLocal);
});
