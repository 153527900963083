/* global useAuth */
import { useFetch, showError } from "nuxt/app";
import { useUser } from "~/composables/useUser";

const expiredTokenCodeStatus = [401, 402];

export const $useFetch = (request, opts) =>
	useFetch(request, {
		headers: {
			"Authorization": `Bearer ${useUser().accessToken}`,
			"Content-Type": "application/json",
		},
		onResponseError({ response }) {
			if (expiredTokenCodeStatus.includes(response.status)) useAuth().signOut();
			if (response.status === 404) throw showError({ statusCode: 404 });
			console.error(response._data);
		},
		...opts,
	});
