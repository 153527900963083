<template>
	<div :class="['aside-menu', { 'aside-menu--collapse': collapseMenu }]">
		<div :class="['aside-menu__wrapper', { 'aside-menu--collapse': collapseMenu }]">
			<div class="aside-menu__header">
				<div class="aside-menu__header-content">
					<NuxtLink
						to="/"
						class="aside-menu__logo"
						title="Главная">
						<div class="aside-menu__logo-image">
							<Logo />
						</div>

						<span class="link link--black">Агрохелпер</span>
					</NuxtLink>

					<button
						class="aside-menu__button-collapse"
						:title="collapseMenu ? 'Развернуть меню' : 'Свернуть меню'"
						@click="collapseMenu = !collapseMenu">
						<NuxtIcon
							class="nuxt-icon--no-fill"
							name="double-arrow" />
					</button>
				</div>
			</div>

			<div class="aside-menu__body">
				<div class="aside-menu__menu">
					<nav
						v-if="authorized()"
						class="aside-menu__nav">
						<template
							v-for="item in mainNavItems"
							:key="item.link">
							<NuxtLink
								v-if="
									menuSettings.checkMain(item.code) &&
									(item.visibility === 'admin' ? userRoles.isAdmin() : item.visibility === 'no-agent' ? !userRoles.isAgent() : true)
								"
								:to="item.link"
								class="aside-menu__nav-item button button--with-icon"
								:title="item.name"
								:external="item.outer"
								:target="item.outer ? '_blank' : ''">
								<NuxtIcon
									:class="`button__icon ${item.class}`"
									:name="item.icon" />

								<span v-if="!collapseMenu">{{ item.name }}</span>
							</NuxtLink>
						</template>

						<button
							v-if="!userRoles.isAgent()"
							class="button button--with-icon aside-menu__nav-item"
							title="ИИ"
							@click="updateCount">
							<NuxtIcon
								class="button__icon"
								name="ai" />

							<span
								v-if="!collapseMenu"
								class="aside-menu__nav-name">
								ИИ
								<span
									v-if="userInfoStore.getUserAiCount.value"
									class="aside-menu__nav-count"
									>{{ userInfoStore.getUserAiCount }}</span
								>
							</span>
						</button>
					</nav>
				</div>

				<div
					v-if="authorized()"
					class="aside-menu__profile">
					<LkUser :aside-shown="collapseMenu" />

					<nav class="aside-menu__nav aside-menu__nav--additional">
						<button
							:class="['aside-menu__nav-item button button--with-icon']"
							title="Выйти"
							@click="logout">
							<NuxtIcon
								class="icon button__icon"
								name="logout" />

							<span v-if="!collapseMenu">Выйти</span>
						</button>

						<template
							v-for="item in additionalMenu"
							:key="item.link">
							<NuxtLink
								:to="item.link"
								:class="['aside-menu__nav-item button button--with-icon', item.class]"
								:title="item.name">
								<NuxtIcon
									class="icon button__icon"
									:name="item.icon" />

								<span v-if="!collapseMenu">{{ item.name }}</span>
							</NuxtLink>
						</template>
					</nav>
				</div>
			</div>

			<div class="aside-menu__bottom aside-menu__links">
				<NuxtLink
					v-for="doc in documents"
					:key="doc.src"
					:to="doc.src"
					class="link aside-menu__link"
					target="_blank"
					:title="doc.fullName"
					external>
					<template v-if="collapseMenu">
						{{ doc.name }}
					</template>

					<template v-else>
						{{ doc.fullName }}
						<NuxtIcon
							class="icon nuxt-icon nuxt-icon--no-fill"
							name="external-link" />
					</template>
				</NuxtLink>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useState, useNuxtApp, useFetch } from "nuxt/app";
	import { storeToRefs } from "pinia";
	import { useUser } from "~/composables/useUser";
	import { useUserInfoStore } from "~/store/userInfo/user-info";
	import { $useFetch } from "~/composables/useFetch";

	const { signOut } = useAuth();
	const configPublic = useRuntimeConfig().public;
	const { api, userRoles, menuSettings } = useNuxtApp();
	const userInfoStore = storeToRefs(useUserInfoStore());

	const collapseMenu = useState("asideMenuCollapse", () => false);

	const mainNavItems = useState("mainNavItems", () => [
		{
			name: "Главная",
			link: "/",
			icon: "house",
			class: "",
			code: "home",
		},
		{
			name: "Поставщики",
			link: "/suppliers",
			icon: "plant",
			class: "",
			code: "suppliers",
		},
		{
			name: "Покупатели",
			link: "/buyers",
			icon: "basket",
			class: "",
			code: "buyers",
		},
		{
			name: "Культуры",
			link: "/cultures",
			icon: "cultures",
			visibility: "admin",
			code: "cultures",
		},
		{
			name: "Регионы",
			link: "/regions",
			icon: "regions",
			visibility: "admin",
			code: "regions",
		},
		{
			name: "Рынок",
			link: `${configPublic.CALCULATOR_DOMAIN}/forms/calc-report/?mode=table&user=${useUser().userId}&role=${useUser().userRoles}&token=${useUser().accessToken}`,
			icon: "chart-bar",
			class: "",
			outer: true,
			code: "market",
		},
		{
			name: "Тариф на перевозку",
			link: "/tariffs",
			icon: "truck",
			class: "nuxt-icon--no-fill",
			visibility: "admin",
			code: "tariffs",
		},
		{
			name: "Цены РЦВ",
			link: "/prices",
			icon: "currency-rub",
			class: "nuxt-icon--no-fill",
			visibility: "admin",
			code: "prices",
		},
		{
			name: "Пользователи",
			link: "/users",
			icon: "person",
			visibility: "no-agent",
			code: "users",
		},
	]);

	const additionalNavItems = useState("additionalNavItems", () => [
		{
			name: "Поддержка",
			link: "/support",
			icon: "message",
			code: "support",
		},
	]);

	const additionalMenu = computed(() => additionalNavItems.value.filter((el) => menuSettings.checkAdditional(el.code)));

	const documents = [
		{
			name: "Политика",
			fullName: "Политика обработки ПД",
			src: "/documents/Политика_обработки_персональных_данных.pdf",
		},
		{
			name: "Оферта",
			fullName: "Оферта",
			src: "/documents/Оферта.pdf",
		},
	];

	const logout = async () => {
		await useFetch(api.logout, {
			onRequest({ options }) {
				options.method = "DELETE";
				options.headers = { "X-Api-Key": "111" };
				options.body = {
					token: useUser().refreshToken,
				};
			},
		});

		await signOut();
	};

	const updateCount = async () => {
		const aiLink = `${configPublic.AI_URL}/?id=${useUser().userId}`;

		await $useFetch(api.updateAiCount(useUser().userId), {
			method: "POST",
			headers: {},
			body: {
				count: 0,
			},
		});

		window.open(aiLink, "_blank");

		await useUserInfoStore().fetchUserInfo();
	};
</script>

<style lang="scss">
	@import "aside-menu";
</style>
