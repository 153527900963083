<template>
	<div class="logo">
		<img
			class="logo__image"
			src="@/assets/images/logo.svg"
			alt="Логотип" />
	</div>
</template>

<style lang="scss">
	@import "logo";
</style>
