import plugin from "vue-yandex-maps";
import { defineNuxtPlugin } from "nuxt/app";
import { useRuntimeConfig } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();

	const settings = {
		apiKey: config.public.YANDEX_MAP_TOKEN,
		lang: "ru_RU",
		coordorder: "latlong",
		debug: false,
		version: "2.1",
	};

	nuxtApp.vueApp.use(plugin, settings);
});
