import { useRuntimeConfig, defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();
	const configSettings = config.public.SIDE_MENU_SETTINGS;

	const quotedPropsRegex = /([\w]+)(?=:)/gi;
	const filteredSettings = configSettings.replace(quotedPropsRegex, '"$1"');

	nuxtApp.menuSettings = {
		...JSON.parse(filteredSettings),
		checkMain(code) {
			return this.main[code] ?? true;
		},
		checkAdditional(code) {
			return this.additional[code] ?? true;
		},
	};
});
