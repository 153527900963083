/* global useAuth */
import { navigateTo, useRuntimeConfig, defineNuxtRouteMiddleware } from "#app";
import { useUser } from "~/composables/useUser";
import { useSerialize } from "~/composables/useSerialize";

export default defineNuxtRouteMiddleware((from) => {
	const redirectUrl = from.query.redirect_uri;

	if (redirectUrl) {
		const { status } = useAuth();
		const origin = useRuntimeConfig().public.auth.computed.origin;

		if (status.value === "authenticated") {
			const newRedirectUrl = new URL(redirectUrl);
			const queryParamsSize = newRedirectUrl.searchParams.size;
			const redirectToUrl = `${newRedirectUrl}${queryParamsSize ? "&" : "?"}token=${useUser().accessToken}&${useSerialize(from.query)}`;

			return navigateTo(redirectToUrl, { external: true, open: { target: "_self" } });
		} else {
			return navigateTo(`/login?callbackUrl=${origin}?redirect_uri=${redirectUrl}&${useSerialize(from.query)}`, { external: true });
		}
	}
});
