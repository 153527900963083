import { useUser } from "~/composables/useUser";
import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.userRoles = {
		agentRoleName: "agent",
		adminRoleName: "admin",
		organizerRoleName: "organizer",
		getUserRoles() {
			return useUser().userRoles || "";
		},
		isAgent() {
			return this.getUserRoles() === this.agentRoleName;
		},
		isAdmin() {
			return this.getUserRoles() === this.adminRoleName;
		},
		isOrganizer() {
			return this.getUserRoles() === this.organizerRoleName;
		},
	};
});
