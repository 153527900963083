<template>
	<div
		class="lk-user"
		:class="{ 'lk-user--minimize': asideShown }">
		<div class="lk-user__header">
			<NuxtIcon
				class="icon"
				name="person-1" />

			<div class="lk-user__info lk-user__hide">
				<span class="lk-user__name p4">{{ userInfoStore.getUserName }}</span>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useUserInfoStore } from "~/store/userInfo/user-info";

	defineProps({
		asideShown: {
			type: Boolean,
			required: true,
			default: true,
		},
		bodyPositionTop: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const userInfoStore = useUserInfoStore();

	await userInfoStore.fetchUserInfo();
</script>

<style lang="scss">
	@import "lk-user";
</style>
