import validate from "/opt/docker/node_modules/nuxt/dist/pages/runtime/validate.js";
import calculator_45auth_45global from "/opt/docker/middleware/calculator-auth.global.js";
import manifest_45route_45rule from "/opt/docker/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  calculator_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "callback-url": () => import("/opt/docker/middleware/callback-url.js"),
  "is-admin-or-organizer": () => import("/opt/docker/middleware/is-admin-or-organizer.js"),
  "is-admin": () => import("/opt/docker/middleware/is-admin.js"),
  "link-from-email": () => import("/opt/docker/middleware/link-from-email.js"),
  auth: () => import("/opt/docker/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}