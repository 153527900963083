<template>
	<div class="layout error">
		<main :class="{ error__main: !isAuthorized }">
			<div class="layout-aside">
				<div
					v-if="isAuthorized"
					class="layout-aside__menu">
					<AsideMenu />
				</div>

				<div class="layout-aside__main error__content">
					<h1 class="error__title">{{ error.statusCode === 404 ? "404" : error.statusCode === 500 ? "500" : "" }}</h1>

					<p>
						{{ error.statusCode === 404 ? "Страница не найдена" : error.statusCode === 500 ? "Ошибка сервера" : "" }}
					</p>

					<p v-if="debugErrorMode === 'true'">{{ error?.message }}</p>

					<UiButton
						v-if="error.statusCode === 404"
						:name="'Вернуться на главную'"
						:size="'lg'"
						@click="useRouter().push('/')" />

					<UiButton
						v-else
						:name="'Обновить страницу'"
						:size="'lg'"
						@click="pageReload" />
				</div>
			</div>
		</main>
	</div>
</template>

<script setup>
	const props = defineProps({
		error: {
			type: Object,
			required: true,
		},
	});

	const debugErrorMode = useRoute().query.debug;

	const pageReload = () => {
		if (typeof window !== "undefined") {
			window.location.reload();
		}
	};

	const { status } = useAuth();

	const isAuthorized = computed(() => status.value !== "unauthenticated");

	const routeToLogin = () => {
		const router = useRouter();
		const trackErrors = [404];

		if (trackErrors.includes(props.error.statusCode) && !isAuthorized.value) {
			router.push("/login");
		}
	};

	routeToLogin();
</script>

<style scoped lang="scss">
	@import "assets/scss/pages/error/error";
</style>
