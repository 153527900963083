import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAsideMenu, LazyAxList, LazyChooseTheAgent, LazyChooseTheCulture, LazyChooseTheLinkedAgent, LazyDadataMap, LazyEntityAuthor, LazyFilterByAddDate, LazyFilterByAgentContract, LazyFilterByAgents, LazyFilterByBindAgents, LazyFilterByCultureStatus, LazyFilterByCultures, LazyFilterByLinkedRequests, LazyFilterByOrganizers, LazyFilterByRegions, LazyFilterByRequestStatus, LazyFilterByTestLaboratory, LazyFilterByTouch, LazyFilterByUpdateDate, LazyFilterSorting, LazyLkUser, LazyLoader, LazyLogo, LazyModalBindingEntity, LazyModalContent, LazyModalDeleteEntity, LazyModalDeleteWarehouse, LazyModalError, LazyModalStatus, LazyModal, LazyPageLoader, LazyPageTitle, LazySearch, LazySelectionAgent, LazySelectionPanel, LazyShowOnPage, LazyStoreInit, LazyTableItem, LazyTable, LazyWarehouseForm, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["AsideMenu", LazyAsideMenu],
["AxList", LazyAxList],
["ChooseTheAgent", LazyChooseTheAgent],
["ChooseTheCulture", LazyChooseTheCulture],
["ChooseTheLinkedAgent", LazyChooseTheLinkedAgent],
["DadataMap", LazyDadataMap],
["EntityAuthor", LazyEntityAuthor],
["FilterByAddDate", LazyFilterByAddDate],
["FilterByAgentContract", LazyFilterByAgentContract],
["FilterByAgents", LazyFilterByAgents],
["FilterByBindAgents", LazyFilterByBindAgents],
["FilterByCultureStatus", LazyFilterByCultureStatus],
["FilterByCultures", LazyFilterByCultures],
["FilterByLinkedRequests", LazyFilterByLinkedRequests],
["FilterByOrganizers", LazyFilterByOrganizers],
["FilterByRegions", LazyFilterByRegions],
["FilterByRequestStatus", LazyFilterByRequestStatus],
["FilterByTestLaboratory", LazyFilterByTestLaboratory],
["FilterByTouch", LazyFilterByTouch],
["FilterByUpdateDate", LazyFilterByUpdateDate],
["FilterSorting", LazyFilterSorting],
["LkUser", LazyLkUser],
["Loader", LazyLoader],
["Logo", LazyLogo],
["ModalBindingEntity", LazyModalBindingEntity],
["ModalContent", LazyModalContent],
["ModalDeleteEntity", LazyModalDeleteEntity],
["ModalDeleteWarehouse", LazyModalDeleteWarehouse],
["ModalError", LazyModalError],
["ModalStatus", LazyModalStatus],
["Modal", LazyModal],
["PageLoader", LazyPageLoader],
["PageTitle", LazyPageTitle],
["Search", LazySearch],
["SelectionAgent", LazySelectionAgent],
["SelectionPanel", LazySelectionPanel],
["ShowOnPage", LazyShowOnPage],
["StoreInit", LazyStoreInit],
["TableItem", LazyTableItem],
["Table", LazyTable],
["WarehouseForm", LazyWarehouseForm],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
