import { defineStore } from "pinia";
import { useNuxtApp } from "nuxt/app";
import { $useFetch } from "~/composables/useFetch";

export const useUserInfoStore = defineStore("user-info", {
	state: () => ({
		userName: "",
		userAiCount: 0,
	}),
	actions: {
		async fetchUserInfo() {
			const { api } = useNuxtApp();
			const thisStore = useUserInfoStore();

			await $useFetch(api.info, {
				method: "GET",
				onResponse({ response }) {
					if (response?.status === 200) {
						thisStore.userName = response._data.data.attributes.name;
						thisStore.userAiCount = response._data.data.attributes.ai_messages_count;
					}
				},
			});
		},
	},
	getters: {
		getUserName() {
			return this.userName;
		},
		getUserAiCount() {
			return this.userAiCount;
		},
	},
});
